import * as React from "react"
import { graphql } from "gatsby"
import HeaderPost from "../templates/header-post.component"
import Footer from "../templates/footer.component"
import { Helmet } from "react-helmet"
import Navigation from "../templates/navigation.component"
import LinksContainer from "../templates/links-container.component"

// markup
const IndexPage = ({
  data,
}) => {    
  const navLinks = data.tags.group
    .filter(tag => tag.totalCount >= 1)
    .map(tag=> { return {name: `${tag.tag} (${tag.totalCount})`, href: "/tags/" + tag.tag.replace("@", "") + "/" }});  
  
  return (
    <main className="">
      <Helmet>
        <title>bitCobblers </title>
        <html lang="en" />
      </Helmet>
      <HeaderPost></HeaderPost>
      <Navigation />
      <div className="max-w-screen-xl mx-auto pb-10">        
        <LinksContainer links={navLinks} /> 
      </div>
      {/* <Footer /> */}
    </main>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: {order: DESC, fields: frontmatter___date}
      filter: {frontmatter: {published: {eq: true}}}
    ) {
      nodes {
        frontmatter {
          slug
          title
          blogger
          date
          tags
          image
          link
        }
        excerpt(pruneLength: 480)
      }
    }
    
    tags: allMdx 
      (filter: {frontmatter: {published: {eq: true}}}){
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }      
    }

    bloggers: allResumeJson {
      edges {
        node {
          basics {
            id
            email
            name
          }
        }
      }
    }
  
  }
`

export default IndexPage