import * as React from "react"
import { Link } from "gatsby"
import logo from "../../static/logo.png"

const HeaderPost = (data) => (        
    <div className="to-white from-blue-50 bg-gradient-to-b w-full">
        <nav className="max-w-screen-xl mx-auto p-5">        
            <Link to="/"><span><img className="h-12" src={logo} /></span></Link>
        </nav>    
    </div>
)

export default HeaderPost