import * as React from "react"

const LinksContainer = ({links}) => (
    <ul className="space-y-2">        
        {links.map((link, index) => (<li className={`${link.className}`} key={index}>
            {!!link.href 
                ? (<a className="px-2 pb-1 hover:rounded-md hover:border hover:border-purple-800/20 hover:bg-blue-500/5" href={link.href}>{link.name}</a>)
                : (<span>{link.name}</span>)}
            </li>))}
    </ul>
  )  

  export default LinksContainer